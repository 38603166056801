import { Box, Divider, Grid, Modal, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
// import USALogo from "assets/icons/UsaIcon.svg";
import WorldIcon from "assets/icons/WorldIcon.svg";
// import UnionLogo from "assets/icons/Union.svg";
import WalletBuyNow from "assets/icons/walletCheckout.svg";
import CalendarLogo from "assets/icons/calendar.svg";
import TagsLogo from "assets/icons/tags.svg";
import WifiLogo from "assets/icons/wifi.svg";
import FourGLogo from "assets/icons/4GIcon.svg";
import { S } from "./styled";
import axios from "axios";
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import useMediaQuery from "@mui/material/useMediaQuery";
import ReactCountryFlag from "react-country-flag";
import { useAppContext } from "context/appContext";

interface Props {
  isToggle: boolean;
  handleCloseClose: () => void;
  status: any;
  packType: any;
}

const CustomModal: FC<Props> = ({
  isToggle,
  handleCloseClose,
  status,
  packType,
}) => {
  const matches = useMediaQuery("(max-width:600px)");
  const mobileS = useMediaQuery("(max-width:460px)");

  const [isChecked, setIsChecked] = useState<Boolean>(false);
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const [loader, setLoader] = useState<Boolean>(false);
  const [pack, setPack] = useState<number>(0);
  const [modalData, setModalData] = useState<any>([]);
  const [routeUrl, setRouteUrl] = useState<string>("");
  const [userEmail, setUserEmail] = useState<string>("");
  const [isValid, setIsValid] = useState<Boolean>(false);
  const { cardData } = useAppContext();

  const params = useParams();

  const getCardData = async () => {
    await setIsLoading(true);
    let manipulatedData = await cardData?.filter(
      (data: any) => data._id === params.id
    );
    await setModalData(manipulatedData);
    setIsLoading(false);
  };

  useEffect(() => {
    if (cardData.length) {
      getCardData();
    }
  }, [params.id, cardData.length]);

  useEffect(() => {
    if (modalData.length > 0) {
      switch (modalData[0].packageTypeId) {
        case "6285fef1014c44572e8202b9":
          setPack(1);
          break;
        case "6285ff0a014c44572e8202ba":
          setPack(2);
          break;
        case "6285ff10014c44572e8202bb":
          setPack(3);
          break;
        default:
          setPack(0);
      }
    }
  }, [modalData]);

  function gtag_report_conversion(url: any) {
    var callback = function () {
      if (typeof url != "undefined") {
        window.location = url;
      }
    };
    (window as any).gtag("event", "conversion", {
      send_to: "AW-836950927/Amd-CMuP38EDEI-3i48D",
      event_callback: callback,
    });
    return false;
  }

  const handleBuy = async () => {
    // gtag_report_conversion(window.location.href);
    await setLoader(true);
    await axios
      .post(`${process.env.REACT_APP_BASE_URL}/purchase`, {
        email: userEmail,
        bundleId: modalData[0]._id,
      })
      .then((response: any) => {
        window.open(response.data.data.url, "_self");
        setLoader(false);
      });
  };

  const getExpiry = (validity: number) => {
    if (validity >= 999) {
      return "Never Expires";
    } else {
      if (validity === 1) {
        return validity + " Day";
      } else {
        return validity + " Days";
      }
    }
  };

  // console.log(modalData);
  // console.log(isChecked && userEmail !== "");
  const validateEmail = (e: any) => {
    const emailRegex = /\S+@\S+\.\S+/;
    const email = e.target.value;
    if (emailRegex.test(email)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };
  return (
    <>
      <Modal
        open={isToggle}
        onClose={() => {
          handleCloseClose();
          // setModalDetails({});
          setIsChecked(false);
        }}

        // aria-labelledby="modal-modal-title"
        // aria-describedby="modal-modal-description"
      >
        <S.ModalContainer container>
          {isLoading === true && <CircularProgress />}
          {!isLoading && modalData.length > 0 && (
            <S.ModalBox>
              <S.ModalHeader>
                <S.ModalHeaderText>
                  {/* {modalDetails?.data}
                  {modalDetails?.dataMessage}: */}
                  {modalData[0]?.packageName}
                </S.ModalHeaderText>
                <S.ModalHeaderSubText>
                  {modalData[0]?.country === "All" ||
                  modalData[0]?.country === "All EU" ? (
                    <img
                      src={WorldIcon}
                      alt="WorldIcon"
                      style={{ width: "21px", height: "28px" }}
                    />
                  ) : (
                    <ReactCountryFlag
                      countryCode={modalData[0]?.iso.slice(0, 2)}
                    />
                  )}
                  &nbsp;{" "}
                  {modalData[0]?.country === "All"
                    ? "Global"
                    : modalData[0]?.country}
                </S.ModalHeaderSubText>
              </S.ModalHeader>
              {matches && (
                <S.PriceArea>
                  <S.GntText>${modalData[0].discountedPrice}</S.GntText>
                  {modalData[0].originalPrice !==
                  modalData[0].discountedPrice ? (
                    <S.GntText2>${modalData[0].originalPrice}</S.GntText2>
                  ) : (
                    ""
                  )}
                </S.PriceArea>
              )}

              <Divider sx={{ border: "1px solid #DEE7FD", width: "100%" }} />
              <S.ContentScrollBox>
                {!matches && (
                  <>
                    {pack === 1 && (
                      <>
                        <S.PackageContent sx={{}}>
                          <S.PackagedItemText
                            sx={{
                              borderRight: "2px solid #6F6F73",
                              // width: "19rem !important",
                            }}
                          >
                            <img src={WifiLogo} alt="CalendarLogo logo" />{" "}
                            &nbsp; {modalData[0]?.packageTypes[0]?.name} :{" "}
                            {modalData[0]?.packageTypes[0]?.dataLimitInBytes /
                              (1024 * 1024)}
                            MB
                          </S.PackagedItemText>
                          <S.PackagedItemText>
                            <img src={TagsLogo} alt="TagsLogo logo" /> &nbsp;
                            GIANT Rewards:{" "}
                            {modalData[0]?.packageTypes[0]?.rewards}
                          </S.PackagedItemText>
                        </S.PackageContent>
                      </>
                    )}
                    {pack === 2 && (
                      <>
                        <S.PackageContent>
                          <S.PackagedItemText
                            sx={{
                              borderRight: "2px solid #6F6F73",
                              // width: "21rem !important",
                            }}
                          >
                            <img src={FourGLogo} alt="WifiLogo logo" /> &nbsp;{" "}
                            {modalData[0].country === "All"
                              ? "Global"
                              : modalData[0].country === "All EU"
                              ? " EU Regional"
                              : modalData[0].country}{" "}
                            {modalData[0]?.packageTypes[0]?.name} :{" "}
                            {modalData[0]?.packageTypes[0]?.dataLimitInBytes /
                              (1024 * 1024) /
                              1024}{" "}
                            GB
                          </S.PackagedItemText>
                          <S.PackagedItemText>
                            <img src={TagsLogo} alt="TagsLogo logo" /> &nbsp;
                            GIANT Rewards:{" "}
                            {modalData[0]?.packageTypes[0]?.rewards}
                          </S.PackagedItemText>
                        </S.PackageContent>
                      </>
                    )}
                    {pack === 3 && (
                      <>
                        <S.PackageContent>
                          <S.PackagedItemText
                            sx={{ borderRight: "2px solid #6F6F73" }}
                          >
                            <img src={WifiLogo} alt="CalendarLogo logo" />{" "}
                            &nbsp; {modalData[0]?.packageTypes[0]?.name} :{" "}
                            {modalData[0]?.packageTypes[0]?.dataLimitInBytes /
                              (1024 * 1024)}
                            MB
                          </S.PackagedItemText>
                          <S.PackagedItemText
                            sx={{ borderRight: "2px solid #6F6F73" }}
                          >
                            <img src={FourGLogo} alt="WifiLogo logo" /> &nbsp;{" "}
                            {modalData[0].country === "All"
                              ? "Global"
                              : modalData[0].country === "All EU"
                              ? " EU Regional"
                              : modalData[0].country}{" "}
                            {modalData[0]?.packageTypes[1]?.name} :{" "}
                            {modalData[0]?.packageTypes[1]?.dataLimitInBytes /
                              (1024 * 1024) /
                              1024}{" "}
                            GB
                          </S.PackagedItemText>
                          <S.PackagedItemText>
                            <img src={TagsLogo} alt="TagsLogo logo" /> &nbsp;
                            GIANT Rewards:{" "}
                            {modalData[0]?.packageTypes[1]?.rewards +
                              modalData[0]?.packageTypes[0]?.rewards}
                          </S.PackagedItemText>
                        </S.PackageContent>
                      </>
                    )}
                  </>
                )}
                {!matches && (
                  <Divider
                    sx={{ border: "1px solid #DEE7FD", width: "100%" }}
                  />
                )}
                {matches && (
                  <>
                    {pack === 1 && (
                      <>
                        <S.PackageMobileContent>
                          <S.PackagedItemMobileText
                            sx={{
                              borderRight: "px solid #6F6F73",
                              // width: "19rem !important",
                            }}
                          >
                            {modalData[0]?.packageTypes[0]?.dataLimitInBytes /
                              (1024 * 1024)}{" "}
                            MB Inflight
                          </S.PackagedItemMobileText>
                          <S.PackagedItemMobileText>
                            {modalData[0]?.packageTypes[0]?.rewards} Rewards
                          </S.PackagedItemMobileText>
                        </S.PackageMobileContent>
                      </>
                    )}
                    {pack === 2 && (
                      <>
                        <S.PackageMobileContent>
                          <S.PackagedItemMobileText
                            sx={{
                              borderRight: "1px solid #6F6F73",
                              // width: "21rem !important",
                            }}
                          >
                            {modalData[0]?.packageTypes[0]?.dataLimitInBytes /
                              (1024 * 1024) /
                              1024}{" "}
                            GB eSIM
                          </S.PackagedItemMobileText>
                          <S.PackagedItemMobileText>
                            {modalData[0]?.packageTypes[0]?.rewards} Rewards
                          </S.PackagedItemMobileText>
                        </S.PackageMobileContent>
                      </>
                    )}
                    {pack === 3 && (
                      <>
                        <S.PackageMobileContent>
                          <S.PackagedItemMobileText
                            sx={{ borderRight: "1px solid #6F6F73" }}
                          >
                            {modalData[0]?.packageTypes[0]?.dataLimitInBytes /
                              (1024 * 1024)}
                            MB Inflight
                          </S.PackagedItemMobileText>
                          <S.PackagedItemMobileText
                            sx={{ borderRight: "1px solid #6F6F73" }}
                          >
                            {modalData[0]?.packageTypes[1]?.dataLimitInBytes /
                              (1024 * 1024) /
                              1024}{" "}
                            GB eSIM
                          </S.PackagedItemMobileText>

                          <S.PackagedItemMobileText>
                            {modalData[0]?.packageTypes[1]?.rewards +
                              modalData[0]?.packageTypes[0]?.rewards}{" "}
                            Rewards
                          </S.PackagedItemMobileText>
                        </S.PackageMobileContent>
                      </>
                    )}
                  </>
                )}
                <S.EmailContainer>
                  <S.EmailInput
                    type="email"
                    placeholder="Enter your email *"
                    required="required"
                    onChange={(e: any) => {
                      setUserEmail(e.target.value);
                      validateEmail(e);
                    }}
                  />
                  <S.ContentTextEmail>
                    {modalData[0]?.packageName === "Inflight WiFi Only"
                      ? "Email is used to link your purchases in the GIANT connect mobile app and send eSIM setup instructions."
                      : "Email is used to send your eSIM QR code for activation, send eSIM setup instructions and link your purchases to track usage in the GIANT connect mobile app."}
                  </S.ContentTextEmail>
                </S.EmailContainer>
                <Divider sx={{ border: "1px solid #DEE7FD", width: "100%" }} />
                <S.ContentText>
                  <ul>
                    <li>
                      {matches && <span>* </span>}
                      <span>eSim</span> plans are non-refundable and
                      non-transferable. So, please ensure your device is
                      unlocked AND supports eSim. To verify, you can check{" "}
                      <a
                        href="https://giantconnect.zendesk.com/hc/en-us/articles/4419817382679-e-sim-FAQs"
                        target="_blank"
                        rel="noreferrer"
                      >
                        eSIM FAQs
                      </a>
                      &nbsp;and&nbsp;
                      <a
                        href="https://giantconnect.zendesk.com/hc/en-us/articles/4419826397847-How-to-setup-e-sim"
                        target="_blank"
                        rel="noreferrer"
                      >
                        eSIM setup guide.
                      </a>{" "}
                    </li>
                    <li>
                      {matches && <span>* </span>}
                      <span>GIANT rewards</span> are provided in the GIANT
                      connect mobile app. Portion of the rewards will be
                      received instantly on sign-up and the rest is earned on
                      usage.
                    </li>
                  </ul>
                </S.ContentText>
              </S.ContentScrollBox>

              {status === "failed" && (
                <Typography sx={{ color: "red", width: "100%" }}>
                  Payment Failed !
                </Typography>
              )}
              <S.ConditionContainer
                sx={{
                  gap: matches ? "0.7rem" : "1.5rem",
                }}
              >
                <S.ContentBox>
                  <S.CheckedBox
                    type="checkbox"
                    checked={isChecked}
                    onChange={() => {
                      if (isChecked === true) {
                        setIsChecked(false);
                      } else {
                        setIsChecked(true);
                      }
                    }}
                  ></S.CheckedBox>
                  <S.ConditionText
                    onClick={() => {
                      if (isChecked === true) {
                        setIsChecked(false);
                      } else {
                        setIsChecked(true);
                      }
                    }}
                    sx={{ fontSize: mobileS ? "11px" : "15px" }}
                  >
                    I agree with the above terms and conditions
                  </S.ConditionText>
                </S.ContentBox>
                <S.ModalHeader>
                  <S.ConfirmBtn
                    // disabled={!(isChecked && userEmail !== "")}
                    disabled={!isChecked || !isValid}
                    onClick={() => handleBuy()}
                  >
                    {loader ? (
                      <CircularProgress color="inherit" />
                    ) : (
                      <img src={WalletBuyNow} alt="WalletBuyNow" />
                    )}
                  </S.ConfirmBtn>
                  {!matches && (
                    <span>
                      <S.GntText>${modalData[0].discountedPrice}</S.GntText>

                      {modalData[0].originalPrice !==
                      modalData[0].discountedPrice ? (
                        <S.GntText2>${modalData[0].originalPrice}</S.GntText2>
                      ) : (
                        ""
                      )}
                    </span>
                  )}
                </S.ModalHeader>
              </S.ConditionContainer>
            </S.ModalBox>
          )}
          {isLoading === false &&
            modalData.length === 0 &&
            cardData.length > 0 && <Typography>No Data Found</Typography>}
        </S.ModalContainer>
      </Modal>
    </>
  );
};

export default CustomModal;
