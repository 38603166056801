import React, { FC } from 'react'

interface Props {
  children: React.ReactNode
}

const Content: FC<Props> = ({ children }) => {
  return <div style={{ marginTop: '5rem' }}>{children}</div>
}

export default Content
