import styled from "@emotion/styled";
import { Button, Typography, createTheme, Checkbox, Grid } from "@mui/material";
import { Box } from "@mui/system";

export namespace S {
  export const ModalContainer = styled(Grid)(({ theme }: any) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    padding: "2rem 4rem",
    boxShadow: "24px",
    borderRadius: "8px",
    maxWidth: "807px",
    maxHeight: "90vh",
    // height:"100%",
    overflowX: "hidden",
    overflowY: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "&:focus-visible": {
      outline: "none",
    },

    "&::-webkit-scrollbar": {
      display: "none",
    },

    [theme.breakpoints.down("xl")]: {
      padding: "2rem 3rem",
    },
    [theme.breakpoints.down("lg")]: {
      padding: "2rem 2rem",
      width: "66%",
    },
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  }));
  export const ModalBox = styled(Box)(({ theme }: any) => ({
    alignItems: "center",
    flexDirection: "column",
    display: "flex",
    flexWrap: "wrap",
    gap: "1.5rem",
  }));
  export const CustomModalBox = styled(ModalContainer)(({ theme }: any) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    boxSizing: "border-box",
    maxWidth: "960px",
    maxHeight: "650px",
    width: "960px",
    height: "650px",
    borderRadius: "8px",
    padding: "30px 0 ",

    ":focus-visible": {
      outline: "none",
    },
    img: {
      width: "80px",
      height: "80px",
      marginBottom: "-19px",
    },

    [theme.breakpoints.down("xl")]: {
      // padding: "2rem",
    },
    [theme.breakpoints.down("lg")]: {
      // padding: "2rem",
      width: "70%",
      img: {
        width: "70px",
        height: "70px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      height: "527px",
      padding: "10px 0 0 0 ",
      img: {
        marginBottom: "0",
      },
    },
  }));

  export const CustomModalBoxInnerBox = styled(Box)(({ theme }: any) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    minHeight: "300px",
    img: {
      marginTop: "-55px",
    },

    [theme.breakpoints.down("lg")]: {
      img: {
        width: "70px",
        height: "70px",
      },
    },
  }));

  export const WifiContainer = styled(Box)(({ theme }: any) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    textAlign: "center",
    // borderBottom: `0.5px solid ${theme.palette.primary.main}`,
    padding: "0 1rem 1rem",
    width: "80%",
    lineHeight: "10px",
  }));

  export const EsimContainer = styled(Box)(({ theme }: any) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    textAlign: "center",
    width: "80%",
    padding: "1rem 0 0 0",
  }));

  export const MainText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    // fontSize: "1rem",
    fontSize: "26px",
    lineHeight: "1.7",
    [theme.breakpoints.down("lg")]: {
      fontSize: "24px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "22px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      lineHeight: "1.5",
    },
  }));
  export const SubText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 200,
    // fontSize: "1rem",
    fontSize: "26px",
    lineHeight: "1.7",
    color: "#a9a9a9",
    [theme.breakpoints.down("lg")]: {
      fontSize: "24px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "22px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      lineHeight: "1.5",
    },
  }));

  export const HyperLink = styled.a(
    ({ theme }: any) => `
    color: #45b549;
    text-decoration: none;
    font-weight: 500;
  `
  );

  export const SuccessText = styled(Typography)(({ theme }: any) => ({
    fontWeight: "300",
    fontSize: "36px",
    // lineHeight: "2.625rem",
    fontFamily: "Roboto,sans-serif",
    color: theme.palette.primary.main,
    [theme.breakpoints.down("lg")]: {
      fontSize: "32px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "28px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
    },
  }));

  export const FailureText = styled(SuccessText)(({ theme }: any) => ({
    fontWeight: "lighter",
    fontSize: "36px",
    fontFamily: "Roboto,sans-serif",
    color: "#DE1428",
    marginTop: "-25px",
    [theme.breakpoints.down("md")]: {
      fontSize: "28px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
    },
  }));

  export const ModalHeader = styled(Box)(({ theme }: any) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    width: "100%",
    gap: "1rem",
    // position:"fixed",
    mHeight: "5rem",
    span: {
      display: "flex",
      gap: "20px",
    },
    [theme.breakpoints.down("lg")]: {
      span: {
        gap: "10px",
      },
    },
  }));

  export const PriceArea = styled(Box)(({ theme }: any) => ({
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-start",
      gap: "10px",
    },
  }));
  export const ModalHeaderText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: "2.25rem",
    lineHeight: "47px",
    color: "#45B549",
    [theme.breakpoints.down("xl")]: {
      fontSize: "31px",
      fontWeight: 600,
      lineHeight: "40px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "22px",
      lineHeight: "25px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "20px",
      lineHeight: "20px",
      span: {
        display: "none",
      },
    },
    "@media(max-width:450px)": {
      fontSize: "16px",
    },
  }));
  export const ModalHeaderSubText = styled(Box)(({ theme }: any) => ({
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "28px",
    color: "#0B213E",
    display: "flex",
    alignItems: "center",
    letterSpacing: "-0.02em",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  }));
  export const PackageContainer = styled(Box)(({ theme }: any) => ({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "1rem",
  }));
  export const PackageText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "23px",
    color: "#0B213E",
    textAlign: "start",
    width: "100%",
    [theme.breakpoints.down("xl")]: {
      fontSize: "16px",
      lineHeight: "20px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "15px",
    },
  }));
  export const PackageHeading = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "23px",
    color: "#45B549",
    textAlign: "start",
    width: "100%",
    [theme.breakpoints.down("xl")]: {
      fontSize: "16px",
      lineHeight: "20px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
      lineHeight: "0px",
    },
  }));
  export const PackageContent = styled(Box)(({ theme }: any) => ({
    // justifyContent: "space-between",
    width: "100%",
    // display: "flex",
    // flexDirection: "column",
    // alignItems: "center",
    gap: "1rem",
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit,minmax(200px,1fr))",
    // flexWrap: "wrap",
  }));

  export const PackageMobileContent = styled(Box)(({ theme }: any) => ({
    // justifyContent: "space-between",
    width: "100%",
    // display: "flex",
    // flexDirection: "column",
    // alignItems: "center",
    gap: "0.5rem",
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit,minmax(88px,1fr))",
    // flexWrap: "wrap",
  }));

  export const PackageItem = styled(Box)(({ theme }: any) => ({
    justifyContent: "center",
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    flexWrap: "wrap",
  }));
  export const PackageItemText = styled(PackageText)(({ theme }: any) => ({
    fontWeight: 400,
    textAlign: "start",
    // width: "100%",
    display: "flex",
    alignItems: "center",
    // justifyContent:"center",
    fontSize: "16px",
  }));
  export const PackagedItemMobileText = styled(Typography)(
    ({ theme }: any) => ({
      // fontWeight: 400,
      textAlign: "start",
      width: "100%",
      display: "flex",
      alignItems: "center",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "20px",
      color: "#000000",
      // [theme.breakpoints.down("xl")]: {
      // },
      // [theme.breakpoints.down("lg")]: {
      //   fontSize: "13px",
      // },
      // [theme.breakpoints.down("md")]: {
      //   fontSize: "12px",
      // },
    })
  );
  export const PackagedItemText = styled(Typography)(({ theme }: any) => ({
    // fontWeight: 400,
    textAlign: "start",
    width: "100%",
    display: "flex",
    alignItems: "center",
    fontWeight: 500,
    // fontSize: "17px",
    lineHeight: "23px",
    color: "#000000",
    // justifyContent:"center",
    fontSize: "16px",
    // [theme.breakpoints.down("xl")]: {
    // },
    // [theme.breakpoints.down("lg")]: {
    //   fontSize: "13px",
    // },
    // [theme.breakpoints.down("md")]: {
    //   fontSize: "12px",
    // },
  }));
  export const PackageItemSubText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "23px",
    color: "#0B213E",
    //  textAlign: "start",
    width: "100%",
    position: "relative",
    left: "20%",
    [theme.breakpoints.down("xl")]: {
      fontSize: "17px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "13px",
    },
  }));

  export const EmailContainer = styled(Box)(({ theme }: any) => ({
    // justifyContent: "space-between",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "flex-start",
    gap: "1rem",
    flexWrap: "wrap",
  }));

  export const EmailInput = styled.input(({ theme }: any) => ({
    height: "42px",
    width: "347px",
    background: "rgba(206, 214, 224,0.25)",
    color: "#45B549",
    border: "none",
    outline: "none",
    paddingLeft: "10px",
    fontSize: "16px",
    fontWeight: "500",
    borderRadius: "8px",

    "::placeholder": {
      fontSize: "16px",
    },

    [theme.breakpoints.down("sm")]: {
      width: "95%",
      color: "#66717B",
    },
  }));

  export const ContentBox = styled(Box)(({ theme }: any) => ({
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    gap: "1rem",
    width: "100%",
    flexWrap: "wrap",
  }));

  export const ContentTextEmail = styled(Typography)(({ theme }: any) => ({
    fontWeight: 400,
    fontSize: "15px",

    color: "#66717B",
    [theme.breakpoints.down("xl")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "12px",
    },
  }));

  export const ContentText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "21px",
    color: "#66717B",
    a: {
      color: "#3b78b6",
      fontWeight: 500,
    },
    ul: {
      margin: "0px ",
      padding: "0 0 0 15px",

      [theme.breakpoints.down("sm")]: {
        listStyle: "none",
        padding: "0px",
      },

      li: {
        marginBottom: "15px",

        a: {
          color: "#23669d",
        },

        "&:last-child": {
          marginBottom: "0px",
        },

        [theme.breakpoints.down("sm")]: {
          span: {
            fontWeight: "bold",
          },
        },
      },
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: "14px",
      lineHeight: "20px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "14px",
      lineHeight: "18px",
    },
  }));
  export const CheckedBox = styled(Checkbox)(({ theme }: any) => ({
    width: "21px",
    height: "21px",
    borderRadius: "0.25rem",
    border: "1px solid #C5C5C5",
    "&.Mui-checked": {
      color: "#45B549",
    },
  }));

  export const ConditionText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 400,
    // fontSize: "15px",
    lineHeight: "20px",
    color: "#0B213E",
    cursor: "pointer",
    // [theme.breakpoints.down("xl")]: {
    //   fontSize: "14px",
    // },
  }));
  export const ConfirmBtn = styled(Button)(({ theme }: any) => ({
    background: "#C5C5C5",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    color: theme.palette.common.white,
    minWidth: "17rem !important",
    height: "50px",
    boxShadow: "none",
    border: "none",
    fontWeight: 700,
    padding: "0rem 0.625rem",
    fontSize: "1.125rem",
    lineHeight: "1.4375rem",
    "&:enabled": {
      background: theme.palette.primary.main,
      boxShadow: "none",
      color: theme.palette.primary.contrastText,
    },

    [theme.breakpoints.down("lg")]: {
      minWidth: "13.5rem !important",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
    },
    [theme.breakpoints.only("sm")]: {
      minWidth: "12rem !important",
    },
    [theme.breakpoints.only("xs")]: {
      minWidth: "12rem !important",
    },
  }));
  export const GntText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: "36px",
    lineHeight: "47px",
    color: "#45B549",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("lg")]: {
      fontSize: "25px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "22px",
    },
    [theme.breakpoints.down("sm")]: {
      lineHeight: "0px",
      fontSize: "20px",
      color: " #0B213E",
    },
  }));
  export const GntText2 = styled(GntText)(({ theme }: any) => ({
    color: "#DE1428",
    fontSize: "22px",
    textDecoration: "line-through",
    [theme.breakpoints.down("lg")]: {
      fontSize: "20px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.down("sm")]: {
      lineHeight: "0px",
      fontSize: "15px",
      color: "#DE1428",
    },
  }));
  export const ContentScrollBox = styled(Box)(({ theme }: any) => ({
    display: "flex",
    flexWrap: "wrap",
    overflowX: "hidden",
    overflowY: "scroll",
    gap: "1.5rem",
    padding: "0",
    margin: "0",
    // height: "calc(90vh - 33rem)",
    "&::-webkit-scrollbar": {
      width: "5px",
      backgroundColor: "white",
    },

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#90A4AE",
      borderRadius: "10px",
    },
  }));
  export const ConditionContainer = styled(Box)(({ theme }: any) => ({
    width: "100%",
    // maxHeight: "6rem",
    // padding: "1rem 0 0 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  }));
}
