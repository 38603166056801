import React, { FC } from 'react'

const NotFound: FC = () => (
  <div style={{ height: 'calc(100vh - 21rem)', display: 'grid', placeContent: 'center' }}>
    <h1 style={{ textAlign: 'center' }}>404</h1>
    <h2 style={{ textAlign: 'center' }}>Page Not Found</h2>
  </div>
)

export default NotFound
