import React, { FC } from "react";
import Select from "react-select";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

// import DownArrow from "assets/icons/DownArrow.svg";
interface Props {
  value: any;
  Option: any;
  setValue: any;
  Control: any;
  placeholder: string;
}

const CustomDropDown: FC<Props> = ({
  value,
  Option,
  setValue,
  Control,
  placeholder,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Select
        options={Option}
        value={value}
        onChange={setValue}
        isSearchable
        components={{ Control }}
        placeholder={placeholder}
        styles={{
          placeholder: (base) => ({
            ...base,
            opacity: "0.8",
            fontWeight: 500,
            fontSize: matches ? "18px" : "24px",
            lineHeight: matches ? "28px" : "31px",
            color: "#000000",
          }),
          control: (base) => ({
            ...base,
            background: "#e2e7ee",
            border: "none",
            minHeight: "44px",
            borderRadius: "8px !important",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "1rem",
            fontWeight: 500,
            fontSize: "18px",
            letterSpacing: "-0.02em",
            lineHeight: "28px",
            color: "#0B213E",
            cursor: "pointer",
            padding: matches ? "0rem 1rem" : "0.5rem 1rem",
            // maxWidth: "260px",
            minWidth: matches ? "160px" : "230px",
          }),
          indicatorsContainer: (base) => ({
            ...base,
            display: "none",
          }),
          singleValue: (base) => ({
            ...base,
            opacity: "0.8",
            fontWeight: 500,
            fontSize: matches ? "18px" : "24px",
            lineHeight: matches ? "28px" : "31px",
            color: "#000000",
          }),
          container: (base) => ({
            ...base,
            display: "flex",
            borderRadius: "0.25rem",
            justifyContent: "center",
          }),
          menu: (base) => ({
            ...base,
            maxWidth: "25rem",
            "@media(max-width:600px)": {
              maxWidth: "20rem",
            },
          }),
        }}
      />
    </>
  );
};

export default CustomDropDown;
