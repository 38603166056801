import { Box, CircularProgress, Modal } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { S } from "./styled";
import GreenCheck from "assets/GreenCheck.svg";
import PrimaryButton from "../buttons/PrimaryButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useAppContext } from "context/appContext";

interface Props {
  isToggle: boolean;
  handleCloseClose: () => void;
}

const APP_URL = "https://wific0in.app.link/4Or8mqMgsqb";

const SuccessModal: FC<Props> = ({ isToggle, handleCloseClose }) => {
  const { cardData, packageTypes } = useAppContext();
  const matches = useMediaQuery("(max-width:500px)");
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const [modalData, setModalData] = useState<any>({});
  const params = useParams();

  const getCardData = async () => {
    await setIsLoading(true);
    let manipulatedData = await cardData?.filter(
      (data: any) => data._id === params.id
    );
    setModalData(manipulatedData[0]);
    setIsLoading(false);
  };

  function gtag_report_conversion(url: any) {
    var callback = function () {
      if (typeof url != "undefined") {
        window.location = url;
      }
    };
    (window as any).gtag("event", "conversion", {
      send_to: "AW-836950927/eP6XCMSg38EDEI-3i48D",
      transaction_id: "",
      event_callback: callback,
    });
    return false;
  }

  useEffect(() => {
    if (cardData.length) {
      getCardData();
    }
  }, [params.id, cardData.length]);

  return (
    <>
      <Modal
        sx={{ border: "none" }}
        open={isToggle}
        onClose={handleCloseClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <S.CustomModalBox>
          {isLoading && !modalData?.packageTypeId ? (
            <CircularProgress />
          ) : (
            <>
              <img src={GreenCheck} alt="" />
              <S.SuccessText variant="h4">Purchase Successful</S.SuccessText>
              <Box
                sx={{
                  padding: "0rem 0rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                  boxSizing: "border-box",
                }}
              >
                {modalData?.packageTypeId === "6285fef1014c44572e8202b9" && (
                  <>
                    <S.WifiContainer>
                      <S.MainText>
                        {modalData?.packageTypes[0]?.name} :{" "}
                        {modalData?.packageTypes[0]?.dataLimitInBytes /
                          (1024 * 1024)}
                        MB
                      </S.MainText>
                      <S.SubText>
                        You will need to download the{" "}
                        <S.HyperLink href={APP_URL} target="_blank">
                          GIANT Connect app{" "}
                        </S.HyperLink>
                        to use your inflight purchase.
                      </S.SubText>
                    </S.WifiContainer>
                  </>
                )}
                {modalData?.packageTypeId === "6285ff0a014c44572e8202ba" && (
                  <>
                    <S.EsimContainer>
                      <S.MainText>
                        {modalData.country === "All"
                          ? "Global"
                          : modalData.country === "All EU"
                          ? " EU Regional"
                          : modalData.country}{" "}
                        {modalData?.packageTypes[0]?.name} :{" "}
                        {modalData?.packageTypes[0]?.dataLimitInBytes /
                          (1024 * 1024) /
                          1024}{" "}
                        GB
                      </S.MainText>
                      <S.SubText>
                        You will receive an email with a QR code to set up your
                        eSIM.Please download the{" "}
                        <S.HyperLink href={APP_URL} target="_blank">
                          GIANT Connect app{" "}
                        </S.HyperLink>{" "}
                        to track your plan usage.
                      </S.SubText>
                    </S.EsimContainer>
                  </>
                )}
                {modalData?.packageTypeId === "6285ff10014c44572e8202bb" && (
                  <>
                    <S.WifiContainer>
                      <S.MainText>
                        {modalData?.packageTypes[0]?.name} :{" "}
                        {modalData?.packageTypes[0]?.dataLimitInBytes /
                          (1024 * 1024)}
                        MB
                      </S.MainText>
                      <S.SubText>
                        You will need to download the{" "}
                        <S.HyperLink href={APP_URL} target="_blank">
                          GIANT Connect app{" "}
                        </S.HyperLink>{" "}
                        to use your inflight purchase.
                      </S.SubText>
                    </S.WifiContainer>
                    <hr
                      style={{ width: "80%", border: "0.5px solid #45B549" }}
                    />
                    <S.EsimContainer>
                      <S.MainText>
                        {modalData.country === "All"
                          ? "Global"
                          : modalData.country === "All EU"
                          ? " EU Regional"
                          : modalData.country}{" "}
                        {modalData?.packageTypes[1]?.name} :{" "}
                        {modalData?.packageTypes[1]?.dataLimitInBytes /
                          (1024 * 1024) /
                          1024}{" "}
                        GB
                      </S.MainText>
                      <S.SubText>
                        You will receive an email with a QR code to set up your
                        eSIM.Please download the{" "}
                        <S.HyperLink href={APP_URL} target="_blank">
                          GIANT Connect app{" "}
                        </S.HyperLink>{" "}
                        to track your plan usage.
                      </S.SubText>
                    </S.EsimContainer>
                  </>
                )}
              </Box>

              <PrimaryButton
                style={{
                  height: "44px",
                  width: `${matches ? "280px" : "350px"}`,
                }}
                onClick={() => {
                  // gtag_report_conversion(window.location.href);
                  handleCloseClose();
                }}
              >
                Ok, got it
              </PrimaryButton>
            </>
          )}
        </S.CustomModalBox>
      </Modal>
    </>
  );
};

export default SuccessModal;
