import { FC, Fragment, useContext, useEffect, useState } from "react";
import { Box, Card, Container, Typography, useMediaQuery } from "@mui/material";
import PrimaryCard from "components/cards/PrimaryCard";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import AngleLeft from "assets/icons/AngleLeft.svg";
import AngleRight from "assets/icons/AngleRight.svg";
import UnitedImg from "assets/united.svg";
import AerLingusImg from "assets/aerLingus.svg";
import AustrianImg from "assets/austrian.svg";
import LufthansaImg from "assets/lufthansa.svg";
import AirEuropaImg from "assets/airEuropa.svg";
import AirPortugalImg from "assets/airPortugal.svg";
import FlightBannerImg from "assets/flightBanner.svg";
import { S } from "./styled";
import axios from "axios";
import { components, ControlProps } from "react-select";
import DownArrow from "assets/icons/DownArrow.svg";
import WorldIcon from "assets/icons/WorldIcon.svg";
import CustomDropDown from "./CustomDropDown";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { PACKAGE_TYPE } from "types";
import {
  GETPACKID_BY_NAME,
  GETPACKNAME_BY_ID,
  REGION_OPTIONS,
} from "utils/constants";
import CustomModal from "components/Modal";
import SuccessModal from "components/Modal/SuccessModal";
import FailureModal from "components/Modal/FailureModal";
import { useTheme } from "@mui/material/styles";
import ReactCountryFlag from "react-country-flag";
import { useAppContext } from "context/appContext";

// import TransactionSuccess from "../transaction-success";
// import TransactionFailed from "../transaction-failed";

const Control = ({ children, ...props }: ControlProps) => {
  return (
    <>
      <components.Control {...props}>
        {children} <img src={DownArrow} alt="arrow" />
      </components.Control>
    </>
  );
};

const LandingPage: FC = () => {
  const { cardData, packageTypes, isLoading } = useAppContext();
  const theme = useTheme();
  const [cardDetails, setCardDetails] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  // const [allDetails, setAllDetails] = useState<any>([]);
  // const [modalDetails, setModalDetails] = useState<any>([]);
  // const [packageTypes, setPackageTypes] = useState<any>([]);
  const [isChecked, setIsChecked] = useState<any>({});
  const [isToggle, setIsToggle] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();
  const [routeUrl, setRouteUrl] = useState("");
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const [isPackageType, setIsPackageType] = useState<any>([
    // "6285ff10014c44572e8202bb",
  ]);
  const [selectRegion, setSelectRegion] = useState<any>({
    value: "Global",
    label: "Global",
  });
  const [selectCountry, setSelectCountry] = useState<any>({
    value: "",
    label: "",
    countryCode: "",
  });

  const [countryOption, setCountyryOption] = useState<any>([]);
  const [regionOption, setRegionOption] = useState<any>([]);

  const navigate = useNavigate();

  const CountryControl = ({ children, ...props }: ControlProps) => {
    // console.log("props",props)
    return (
      <>
        <components.Control {...props}>
          {selectCountry?.value === "All" ||
          selectCountry?.value === "All EU" ? (
            <img
              src={WorldIcon}
              alt="WorldIcon"
              style={{ width: "21px", height: "28px" }}
            />
          ) : (
            <ReactCountryFlag countryCode={selectCountry.countryCode} />
          )}
          {children} <img src={DownArrow} alt="arrow" />
        </components.Control>
      </>
    );
  };
  useEffect(() => {
    // if (!searchParams.get("status")) setSearchParams("");
    // getPackageTypes();
    // getCardDetails();
    const type = searchParams.get("type");
    if (type) {
      const types = type.split(",");
      const typeIds: any = types.map((type: any) => {
        return GETPACKID_BY_NAME(type);
      });
      setIsPackageType(typeIds);
    } else {
      setIsPackageType([
        "6285ff10014c44572e8202bb",
        "6285ff0a014c44572e8202ba",
        "6285fef1014c44572e8202b9",
      ]);
    }

    const region: any = searchParams.get("region");
    if (region) {
      setSelectRegion({
        value: region.replace("-", "&"),
        label: region.replace("-", "&"),
      });
    } else {
      setSelectRegion({
        value: "Global",
        label: "Global",
      });
      // setIsPackageType(["6285ff10014c44572e8202bb"]);
    }

    async function getCountry() {
      const country: any = searchParams.get("country");
      if (country) {
        let regionData = region.replace("-", "&");

        let contryFlag: any = await cardData.filter(
          (data: any) => data.country === country && data.region === regionData
        );
        setSelectCountry({
          value: country.replace("-", "&"),
          label: country.replace("-", "&"),
          countryCode: contryFlag.length ? contryFlag[0]?.iso.slice(0, 2) : "",
        });
      } else {
        setSelectCountry({
          value: "",
          label: "",
          countryCode: "",
        });
        // setIsPackageType(["6285ff10014c44572e8202bb"]);
      }
    }
    getCountry();
  }, [cardData.length]);

  // useEffect(() => {
  //   getCardDetails();
  //   // console.log("called");
  // }, []);

  const handleCloseClose = () => {
    setIsToggle(false);
    navigate(`/packages?type=${routeUrl}`);
  };

  // const getCardDetails = async () => {
  //   await setLoading(true);
  //   await axios
  //     .get(`${process.env.REACT_APP_BASE_URL}/getPackageByType`)
  //     .then((response: any) => {
  //       setAllDetails(response.data);
  //       // console.log("response.data", response.data);
  //       setLoading(false);
  //     });
  // };

  useEffect(() => {
    if (cardData.length) {
      getFilter();
    }
  }, [isPackageType.length, selectRegion, selectCountry, cardData.length]);

  const getFilter = async () => {
    await setLoading(true);
    // console.log("cardData", cardData);
    let filteredData = await [...cardData.reverse()];
    // console.log("isLoading",loading)
    if (selectRegion.value.length) {
      filteredData = filteredData.filter((item: any) => {
        return item.region === selectRegion.value;
      });
    }
    if (selectCountry.value.length) {
      if (selectRegion.value !== searchParams.get("region")) {
        setSelectCountry({
          value: "",
          label: "",
          countryCode: "",
        });
      }
      filteredData = filteredData.filter((item: any) => {
        return item.country === selectCountry.value;
      });
    }
    if (isPackageType.length) {
      filteredData = filteredData.filter((item: any) =>
        isPackageType.includes(item.packageTypeId)
      );
    }
    await setCardDetails(filteredData);
    setLoading(false);
  };

  // const getPackageTypes = () => {
  //   axios
  //     .get(`${process.env.REACT_APP_BASE_URL}/getPackageTypes`)
  //     .then((response: any) => {
  //       setPackageTypes(response?.data.reverse());
  //     });
  // };

  useEffect(() => {
    if (searchParams.get("status") || params.id) {
      setIsToggle(true);
    }
  }, [searchParams.get("status")]);

  useEffect(() => {
    let queryParam = "";
    isPackageType.forEach((item: any, index: any) => {
      if (index === 0) {
        queryParam += `${GETPACKNAME_BY_ID(item)}`;
      } else {
        queryParam += `,${GETPACKNAME_BY_ID(item)}`;
      }
    });
    if (selectRegion.value !== "") {
      queryParam += `&region=${selectRegion.value.replace("&", "-")}`;
    }
    if (selectCountry.value !== "") {
      queryParam += `&country=${selectCountry.value.trim()}`;
    }
    if (
      isPackageType.length > 0 &&
      searchParams.get("status") !== "success" &&
      searchParams.get("status") !== "failed" &&
      !params.id
    ) {
      navigate(`/packages?type=${queryParam}`);
      setRouteUrl(queryParam);
    }
  }, [isPackageType.length, selectRegion, selectCountry]);

  useEffect(() => {
    const regionList = cardData.map((data: any) => {
      return { value: data.region, label: data.region };
    });

    const updatedRegionList = Array.from(
      new Set(regionList.map((el: any) => JSON.stringify(el)))
    ).map((el: any) => JSON.parse(el));

    // setCountyryOption(updatedCountryList);
    setRegionOption(updatedRegionList);
  }, [cardData.length]);

  useEffect(() => {
    const countryList = cardData
      .filter((data: any) => data.region === selectRegion.value)
      .map((data: any) => {
        // console.log("iso",data.iso)
        return {
          value: data.country,
          label: data.country,
          countryCode: data?.iso.slice(0, 2),
        };
      });

    // console.log("countryList", countryList);
    const updatedCountryList = Array.from(
      new Set(countryList.map((el: any) => JSON.stringify(el)))
    ).map((el: any) => JSON.parse(el));
    // console.log("updatedCountryList", updatedCountryList);

    setCountyryOption(updatedCountryList);
  }, [cardData.length, selectRegion.value]);

  // console.log("isPackageType", isPackageType);
  // console.log("cardDetails", cardDetails);
  // console.log("selectCountry", selectCountry);
  // console.log("selectRegion", selectRegion);
  // useEffect(()=>{
  // isPackageType
  // },[])
  return (
    <>
      <S.HeaderContainer maxWidth={false}>
        {!matches && (
          <>
            <S.HeaderContainerText>
              <span style={{ color: "#45B549"}}>
                Pay as you go
              </span>{" "}
              Inflight WiFi & Global eSIM Data Roaming
            </S.HeaderContainerText>
            <S.HeaderContainerSubText>
              1000s of flights. eSim in 118 countries. Earn crypto rewards for
              buying and using data!
            </S.HeaderContainerSubText>
            <S.HeaderContainerBox>
              <img src={UnitedImg} alt="unitedImg" />
              <img src={AerLingusImg} alt="AerLingusImg" />
              <img src={AustrianImg} alt="AustrianImg" />
              <img src={LufthansaImg} alt="LufthansaImg" />
              <img src={AirPortugalImg} alt="AirPortugalImg" />
              <img src={AirEuropaImg} alt="AirEuropaImg" />
            </S.HeaderContainerBox>
          </>
        )}
        {matches && (
          <>
            <S.MobileHeaderContainer>
              <S.HeaderContainerText>
                <span style={{ color: `${matches ? "#45B549" : "#0B213E"}` }}>
                  Pay as you go
                </span>{" "}
                Inflight WiFi <br /> & Global eSIM Data Roaming
              </S.HeaderContainerText>
              <S.HeaderContainerSubText>
                eSim in 118 countries <br /> Earn crypto rewards
              </S.HeaderContainerSubText>
            </S.MobileHeaderContainer>
            <img
              src={FlightBannerImg}
              alt="FlightBannerImg"
              style={{ width: "100%" }}
            />

            {/* <S.MobileHeaderCard>
              <Box sx={{ padding: "0.5rem 0.5rem 0rem 0.5rem" }}>
                <S.MobileHeaderCardTitle>
                  Buy before you fly and save!
                </S.MobileHeaderCardTitle>
                <S.MobileHeaderCardSubTitle>
                  You won’t be able to get this deal on the plane!
                </S.MobileHeaderCardSubTitle>
              </Box>
              <hr style={{ border: "0.3px solid #76BF43", width: "100%" }} />
              <S.HeaderContainerBox>
                <img src={UnitedImg} alt="unitedImg" />
                <img src={AerLingusImg} alt="AerLingusImg" />
                <img src={AustrianImg} alt="AustrianImg" />
                <img src={LufthansaImg} alt="LufthansaImg" />
                <img src={AirPortugalImg} alt="AirPortugalImg" />
                <img src={AirEuropaImg} alt="AirEuropaImg" />
              </S.HeaderContainerBox>
            </S.MobileHeaderCard> */}
          </>
        )}
      </S.HeaderContainer>
      <S.CustomContainer maxWidth={false}>
        <S.MenuContainer>
          {packageTypes.map((data: any, index: any) => {
            return (
              <S.MenuContainerBox
                sx={{
                  border:
                    isPackageType[0] === data._id ||
                    isPackageType[1] === data._id ||
                    isPackageType[2] === data._id
                      ? "1px solid #45B549"
                      : "1px solid #646464",
                }}
                onClick={() => {
                  if (
                    isPackageType[0] === data._id ||
                    isPackageType[1] === data._id ||
                    isPackageType[2] === data._id
                  ) {
                    setIsPackageType(
                      isPackageType.filter((item: any) => item !== data._id)
                    );
                  } else {
                    setIsPackageType((prev: any) => [...prev, data._id]);
                  }
                }}
                key={data._id}
              >
                <S.MenuContainerText
                  sx={{
                    color:
                      isPackageType[0] === data._id ||
                      isPackageType[1] === data._id ||
                      isPackageType[2] === data._id
                        ? "#45B549"
                        : "#646464",
                  }}
                >
                  {data.name}
                </S.MenuContainerText>
                <S.MenuContainerCheckBox
                  // defaultChecked={isPackageType[0] === data._id}
                  checked={
                    isPackageType[0] === data._id ||
                    isPackageType[1] === data._id ||
                    isPackageType[2] === data._id
                  }
                  onChange={(e: any) => {
                    if (e.target.checked) {
                      // setIsChecked(true);
                      // navigate(`/packages?type=${data._id}`);
                      setIsPackageType((prev: any) => [...prev, data._id]);
                    } else {
                      setIsPackageType(
                        isPackageType.filter((item: any) => item !== data._id)
                      );
                    }
                  }}
                  icon={<RadioButtonUncheckedIcon />}
                  checkedIcon={<CheckCircleIcon />}
                />
              </S.MenuContainerBox>
            );
          })}
          <CustomDropDown
            value={selectRegion.value && selectRegion}
            Option={regionOption}
            setValue={setSelectRegion}
            Control={Control}
            placeholder={"Region"}
          />
          <CustomDropDown
            value={selectCountry.value && selectCountry}
            Option={countryOption}
            setValue={setSelectCountry}
            Control={CountryControl}
            placeholder={"Country"}
          />
        </S.MenuContainer>
        <S.CardContainer maxWidth="xl">
          {cardDetails.length !== 0 &&
            cardDetails.map((data: PACKAGE_TYPE) => {
              return (
                <Fragment key={data._id}>
                  <PrimaryCard
                    data={data}
                    handleOpen={() => setIsToggle(true)}
                  />
                </Fragment>
              );
            })}
        </S.CardContainer>
        {(loading || isLoading) && <CircularProgress />}
        {!loading && cardDetails.length === 0 && cardData.length > 0 && (
          <Typography>Packs not available</Typography>
        )}
        {!isLoading && cardData.length === 0 && (
          <Typography>Packs not available1</Typography>
        )}
        {/* <Container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            gap: "1rem",
            margin: "1rem 0",
            width: "100%",
          }}
        >
          <Typography sx={{ color: "#66717B" }}>
            Showing 1 - 3 out of 3
          </Typography>
          <Box sx={{ display: "flex", gap: "4px" }}>
            <S.PageSideBtn size="small">
              <img src={AngleLeft} alt="leftLogo" />
            </S.PageSideBtn>
            <S.PageBtn size="small" variant="contained">
              1
            </S.PageBtn>
            <S.PageSideBtn size="small">
              <img src={AngleRight} alt="leftLogo" />
            </S.PageSideBtn>
          </Box>
        </Container> */}
      </S.CustomContainer>

      {/* )} */}
      {/* {searchParams.get("status") === "success" && <TransactionSuccess />} */}
      {/* {searchParams.get("status") === "failed" && <TransactionFailed />} */}
      {searchParams.get("status") === null && (
        <CustomModal
          isToggle={isToggle}
          handleCloseClose={handleCloseClose}
          status={searchParams.get("status")}
          packType={packageTypes}
          // data={modalDetails}
        />
      )}
      {searchParams.get("status") === "success" && (
        <SuccessModal isToggle={isToggle} handleCloseClose={handleCloseClose} />
      )}
      {searchParams.get("status") === "failed" && (
        <FailureModal isToggle={isToggle} handleCloseClose={handleCloseClose} />
      )}
    </>
  );
};

export default LandingPage;
