import styled from "@emotion/styled";
import { AppBar, Grid } from "@mui/material";

export namespace S {
    export const CustomAppBar = styled(AppBar)(({ theme }: any) => ({
      background: theme.palette.common.white,
      boxShadow:
        "0px 4px 8px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(11, 33, 62, 0.25)",
      color: theme.palette.text.primary,
      position: "fixed",
      top: 0,
      zIndex: 99,
      height: "5rem",
    }));

    export const HeaderContainer = styled(Grid)(({ theme }: any) => ({
      display: "flex",
      alignItems: "center",
      justifyContent: "start",
      padding: "0rem 2rem",
      height: "100%",
      [theme.breakpoints.down("sm")]: {
        padding: "0rem 0 0 1rem",
      },
    }));

    export const LogoContainer = styled(Grid)(({ theme }) => ({
      cursor: "pointer",
    }));
} 