import styled from "@emotion/styled";
import { Box, Card, Typography } from "@mui/material";

export namespace S {
  export const CustomCard = styled(Card)(({ theme }: any) => ({
    gap: "1.2rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background: theme.palette.primary.contrastText,
    boxShadow:
      "0px 4px 8px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(11, 33, 62, 0.25)",
    borderRadius: "8px",
    padding: "0 0 1rem 0",
    maxWidth: "387px",
    width: "100%",
    // height: "100%",
    // minHeight: "418px",
    [theme.breakpoints.down("sm")]: {
      width: "350px",
    },
  }));

  export const CardHeader = styled(Box)(({ theme }: any) => ({
    minWidth: "88%",
    display: "flex",
    // maxHeight: "160px",
    height: "58px",
    padding: "1.5rem 2rem",
    gap: "1rem",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "8px 8px 0px 0px",
    // background: "linear-gradient(113.74deg, #DE1428 9.6%, #FF6B6B 91.34%)",
  }));
  export const CardHeaderText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "23px",
    color: "#FFFFFF",
  }));
  export const CardHeaderSubText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 400,
    lineHeight: "17px",
    fontSize: "13px",
    color: "#FFFFFF",
  }));  
  export const CardHeaderBox = styled(Box)(({ theme }: any) => ({
    display: "flex",
    justifyContent: "start",
    // alignItems: "center",
    gap: "0.5rem",
    flexDirection: "column",
  }));
  export const ContentBox = styled(Box)(({ theme }: any) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.5rem",
    flexDirection: "column",
    flexWrap: "wrap",
    padding: "0 1rem",
  }));

  export const HeaderText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: "1.5rem",
    lineHeight: "1.9375rem",
    color: "#0B213E",
    textAlign: "center",
  }));


  export const MainContent = styled(ContentBox)(({ theme }: any) => ({
    justifyContent: "start",
    alignItems: "start",
    minWidth: "84%",
    height: "9.5rem",
  }));

  export const MainText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "23px",
    color: "#0B213E",
  }));

    export const MainSubText = styled(MainText)(({ theme }: any) => ({
      fontWeight: 400,
    }));

  export const MainBox = styled(Box)(({ theme }: any) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "0.5rem",
    width:"100%",
    flexWrap:"wrap"
  }));

  export const MainContentBox = styled(Box)(({ theme }: any) => ({
    display: "flex",
    gap: "0.5rem",
  }));

  export const MainContentBoxText = styled(MainText)(({ theme }: any) => ({
    textAlign:"right"
  }));


  export const FooterContent = styled(Box)(({ theme }: any) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.5rem",
    width: "90%",
    flexWrap: "wrap",
  }));
}
