import TwitterLogo from "assets/icons/TwitterIcon.svg";
import LinkedinLogo from "assets/icons/LinkedInIcon.svg";
import TelegramLogo from "assets/icons/TelegramIcon.svg";
import FacebookLogo from "assets/icons/FacebookIcon.svg";
import WifiImg from "assets/icons/wifi.svg";
import CalendarImg from "assets/icons/calendar.svg";
import TagsImg from "assets/icons/tags.svg";
import Img4G from "assets/icons/Img4G.svg";
import { SOCIAL_ICONSTYPE } from "types";

export const SOCIAL_ICONS: Array<SOCIAL_ICONSTYPE> = [
  {
    id: 1,
    icon: TwitterLogo,
    link: "https://www.twitter.com/giantconnectapp",
  },
  {
    id: 2,
    icon: LinkedinLogo,
    link: "https://www.linkedin.com/company/giantconnectapp",
  },
  {
    id: 3,
    icon: TelegramLogo,
    link: "https://t.me/giantprotocol",
  },
  {
    id: 4,
    icon: FacebookLogo,
    link: "https://www.facebook.com/giantconnectapp",
  },
];

export const BUNDLE_OPTIONS = [
  {
    id: 1,
    title: "Inflight + Poland eSIM",
    subTitle: "Buy together & save more",
    price: "20.00",
    subPrice: "$70.99 (30% off)",
    packageDetails: [
      {
        icon: WifiImg,
        name: "Infligth wifi",
        value: "500 MB",
      },
      {
        icon: Img4G,
        name: "Poland eSIM",
        value: "2 GB",
      },
      {
        icon: CalendarImg,
        name: "eSIM Active Period",
        value: "30 Days",
      },
      {
        icon: TagsImg,
        name: "GIANT Rewards",
        value: "800",
      },
    ],
  },
  {
    id: 2,
    title: "Poland eSIM only",
    subTitle: "Lowest price on eSIM",
    price: "11.99",
    subPrice: "",
    packageDetails: [
      {
        icon: Img4G,
        name: "Poland eSIM",
        value: "2 GB",
      },
      {
        icon: CalendarImg,
        name: "eSIM Active Period",
        value: "30 Days",
      },
      {
        icon: TagsImg,
        name: "GIANT Rewards",
        value: "800",
      },
    ],
  },
  {
    id: 3,
    title: "Inflight Wifi Only",
    subTitle: "Save with pay as you go",
    price: "49.99",
    subPrice: "",
    packageDetails: [
      {
        icon: WifiImg,
        name: "Infligth wifi",
        value: "500 MB",
      },
      {
        icon: CalendarImg,
        name: "eSIM Active Period",
        value: "30 Days",
      },
      {
        icon: TagsImg,
        name: "GIANT Rewards",
        value: "800",
      },
    ],
  },
];

export const REGION_OPTIONS = [
  { value: "Global", label: "Global" },
  { value: "US & Canada", label: "US & Canada" },
  { value: "EU", label: "EU" },
  { value: "Asia", label: "Asia" },
  { value: "MENA", label: "MENA" },
  { value: "LATAM", label: "LATAM" },
];

export const COUNTRY_OPTIONS = [
  { value: "All", label: "All" },
  { value: "United States", label: "United States" },
  { value: "All EU", label: "All EU" },
  { value: "Poland", label: "Poland" },
  { value: "Bahrain", label: "Bahrain" },
  { value: "China", label: "China" },
  { value: "Japan", label: "Japan" },
  { value: "Germany", label: "Germany" },
  { value: "France", label: "France" },
  { value: "Spain", label: "Spain" },
  { value: "Saudi Arabia", label: "Saudi Arabia" },
  { value: "Turkey", label: "Turkey" },
  { value: "Canada", label: "Canada" },
  { value: "Mexico", label: "Mexico" },
  { value: "United Kingdom", label: "United Kingdom" },
  { value: "Ireland", label: "Ireland" },
];

export const GETPACKNAME_BY_ID = (id: string) => {
  switch (id) {
    case "6285fef1014c44572e8202b9":
      return "InflightWiFi";
    case "6285ff0a014c44572e8202ba":
      return "eSIM";
    case "6285ff10014c44572e8202bb":
      return "bundle";
    default:
      return "InflightWiFi";
  }
};

export const GETPACKID_BY_NAME = (name: string) => {
  switch (name) {
    case "InflightWiFi":
      return "6285fef1014c44572e8202b9";
    case "eSIM":
      return "6285ff0a014c44572e8202ba";
    case "bundle":
      return "6285ff10014c44572e8202bb";
    default:
      return "6285fef1014c44572e8202b9";
  }
};
