import { Box } from "@mui/system";
import { Grid } from "@mui/material";
import FooterLogo from "assets/icons/GiantConnectFooterLogo.svg";

import { Link } from "react-router-dom";
import { SOCIAL_ICONSTYPE } from "../../../types";
import { SOCIAL_ICONS } from "../../../utils/constants";
import { S } from "./styled";

export const Footer = () => {
  return (
    <>
      <S.CustomFooter maxWidth={false}>
        <S.FooterContainerLeft container>
          {/* <S.FooterLogoContainer item> */}
          <img
            style={{ height: "3rem", marginBottom: "1rem" }}
            src={FooterLogo}
            alt=""
          />
          <S.FooterSubText>
            A global connectivity economy that is <br />
            owned and governed by its users.
          </S.FooterSubText>
          {/* </S.FooterLogoContainer> */}
        </S.FooterContainerLeft>

        <S.FooterContainerRight container>
          <S.FooterSubContainer item>
            <S.FooterHeaderText>Company</S.FooterHeaderText>
            {/* <Box sx={{ display: "flex", flexDirection: "column" }}> */}
            <S.FooterLinks>
              <a
                href="https://giantconnect.com/"
                target="_blank"
                rel="noreferrer"
              >
                About
              </a>
            </S.FooterLinks>

            <S.FooterLinks>
              <a
                href="https://giantconnect.com/blog/"
                target="_blank"
                rel="noreferrer"
              >
                Blog
              </a>
            </S.FooterLinks>
            <S.FooterLinks>
              <a
                href="https://giantconnect.zendesk.com/hc/en-us"
                target="_blank"
                rel="noreferrer"
              >
                Support
              </a>
            </S.FooterLinks>
            {/* </Box> */}
          </S.FooterSubContainer>
          <S.FooterSubContainer item>
            <S.FooterHeaderText>Support</S.FooterHeaderText>
            {/* <Box sx={{ display: "flex", flexDirection: "column" }}> */}
            <S.FooterLinks>
              <a
                href="https://giantconnect.com/privacy-policy/"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
            </S.FooterLinks>
            <S.FooterLinks>
              <a
                href="https://giantconnect.com/terms-of-service/"
                target="_blank"
                rel="noreferrer"
              >
                Term of Service
              </a>
            </S.FooterLinks>
            <S.FooterLinks>&nbsp;</S.FooterLinks>
            {/* </Box> */}
          </S.FooterSubContainer>
          <S.SocialMediaContainer item>
            <S.FooterHeaderText>Social Media</S.FooterHeaderText>
            <Grid sx={{ display: "flex" }}>
              {SOCIAL_ICONS.map((icon: SOCIAL_ICONSTYPE) => {
                return (
                  <a
                    href={icon.link.toString()}
                    rel="noreferrer"
                    key={icon.id}
                    target="_blank"
                  >
                    <img
                      src={icon.icon}
                      style={{
                        marginRight: "1rem",
                        cursor: "pointer",
                        marginTop: "10px",
                      }}
                      alt=""
                    />
                  </a>
                );
              })}
            </Grid>
          </S.SocialMediaContainer>
        </S.FooterContainerRight>
      </S.CustomFooter>
      <S.CopyrightContainer maxWidth={false}>
        @{new Date().getFullYear()}, GIANT Protocol Foundation. All rights
        reserved
      </S.CopyrightContainer>
    </>
  );
};
