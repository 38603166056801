import Box from "@mui/material/Box";
import MainLogo from "assets/icons/GiantConnectLogo.svg";
import MobileViewLogo from "assets/icons/GiantMobileLogo.svg";
import { useNavigate } from "react-router-dom";
import { S } from "./styled";
import { useMediaQuery } from "@mui/material";

export default function Header() {
  const mobileView = useMediaQuery("(max-width:450px)");
  const navigate = useNavigate();

  return (
    <Box>
      <S.CustomAppBar position="static">
        <S.HeaderContainer container>
          <S.LogoContainer item onClick={() => navigate("/packages")}>
            <img src={mobileView ? MobileViewLogo : MainLogo} alt="" />
          </S.LogoContainer>
        </S.HeaderContainer>
      </S.CustomAppBar>
    </Box>
  );
}
