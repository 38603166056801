import { styled } from "@mui/system";
import { Container, Grid, Typography } from "@mui/material";

import { Link } from "react-router-dom";

export namespace S {
  export const CustomFooter = styled(Container)(({ theme }: any) => ({
    background: theme.palette.primary.main,
    minHeight: "215px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    alignItems: "center",

    [theme.breakpoints.down("lg")]: {
      justifyContent: "flex-start",
    },
    // [theme.breakpoints.down("sm")]: {
    //   justifyContent: "flex-start",
    // },
  }));

  // export const FooterLogoContainer = styled(Grid)(({ theme }: any) => ({
  // height: "100%",
  // padding: "2rem 0",
  // width: "35%",
  // minWidth: "350px",
  // [theme.breakpoints.down("sm")]: {
  //   width: "100%",
  // },
  // }));

  export const FooterContainerLeft = styled(Grid)(({ theme }: any) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    width: "auto",
    height: "120px",
    margin: "25px 35px",
    // flexWrap: "wrap",
    // height: "100%",
    // padding: "0 4.3rem",
    // [theme.breakpoints.down("sm")]: {
    //   padding: "0",
    // },
  }));

  export const FooterContainerRight = styled(Grid)(({ theme }: any) => ({
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-start",
    width: "auto",
    height: "auto",
    margin: "0 25px",
    flexWrap: "wrap",
    // paddingRight: "180px",
    paddingRight: "10%",
    // height: "100%",
    // padding: "0 4.3rem",
    [theme.breakpoints.down("lg")]: {
      margin: " 0  0  20px 40px ",
      paddingRight: "0",
    },
    [theme.breakpoints.down("md")]: {
      // margin: " 0",
      // justifyContent: "center",
    },
    [theme.breakpoints.down("sm")]: {
      // justifyContent: "flex-start",
      // margin: "0 25px",
    },
  }));

  export const FooterSubContainer = styled(Grid)(({ theme }: any) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    fontSize: "13.5px",
    height: "140px",
    width: "180px",

    // margin: "25px 0px",
    [theme.breakpoints.down("md")]: {
      width: "150px",
    },
  }));

  export const FooterSubText = styled(Typography)(({ theme }: any) => ({
    color: theme.palette.common.white,
  }));

  export const FooterLinks = styled(Typography)(({ theme }: any) => ({
    textDecoration: "none",

    a: {
      textDecoration: "none",
      color: theme.palette.common.white,
    },
  }));

  export const FooterHeaderText = styled(Typography)(({ theme }: any) => ({
    color: theme.palette.common.white,
    fontSize: "18px",
    fontWeight: 700,
    margin: "10px 0 15px 0",
  }));

  export const SocialMediaContainer = styled(Grid)(({ theme }: any) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    height: "140px",
    width: "auto",
    // margin: "25px 0px",
    [theme.breakpoints.down("md")]: {
      marginTop: "25px",
    },
  }));

  export const CopyrightContainer = styled(Container)(({ theme }: any) => ({
    background: theme.palette.common.black,
    color: theme.palette.common.white,
    display: "grid",
    placeContent: "center",
    height: "3rem",
    fontWeight: 400,
    fontSize: "0.6563rem",
  }));
}
