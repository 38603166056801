import React, { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import NotFound from "../components/notFound/NotFound";
import LandingPage from "../views/landing-page";

const AppRouters: FC = () => (
  <Routes>
    <Route path="/" element={<Navigate to="/packages" replace />} />
    <Route path={`/packages`} element={<LandingPage />} />
    <Route path={`packages/:id`} element={<LandingPage />} />
    {/* <Route path={`/home`} element={<LandingPage />} /> */}
    <Route path="/*" element={<NotFound />} />
  </Routes>
);

export default AppRouters;
