import React, { FC } from 'react'
import Header from './header'
import Content from './content'
import { Footer } from './footer'
import AppRouters from '../../routes/AppRouters'
import { BrowserRouter as Router } from 'react-router-dom'
const Layout: FC = () => {
  return (
    <div className="main-layout">
      <Router>
        <Header />
        <Content>
          <AppRouters />
        </Content>
        <Footer />
      </Router>
    </div>
  )
}

export default Layout
