import { Box, Modal } from "@mui/material";
import { FC } from "react";
import PrimaryButton from "../buttons/PrimaryButton";
import { S } from "./styled";
import RedCheck from "assets/RedCheck.svg";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate, useParams } from "react-router-dom";
interface Props {
  isToggle: boolean;
  handleCloseClose: () => void;
}

const FailureModal: FC<Props> = ({ isToggle, handleCloseClose }) => {
  const matches = useMediaQuery("(max-width:500px)");
  const params = useParams();
  const navigate = useNavigate();
  console.log("params", params);
  return (
    <>
      <Modal
        sx={{ border: "none" }}
        open={isToggle}
        onClose={handleCloseClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <S.CustomModalBox>
          <S.CustomModalBoxInnerBox
          // sx={{
          //   display: "flex",
          //   flexDirection: "column",
          //   alignItems: "center",
          //   marginBottom: "4rem",
          // }}
          >
            <img src={RedCheck} alt="" />
            <S.FailureText>Purchase unsuccessful</S.FailureText>
          </S.CustomModalBoxInnerBox>
          <Box sx={{ position: "absolute", bottom: "3rem" }}>
            <PrimaryButton
              style={{
                height: "44px",
                width: `${matches ? "280px" : "350px"}`,
              }}
              onClick={() => navigate(`/packages/${params?.id}`)}
            >
              Try Again
            </PrimaryButton>
          </Box>
        </S.CustomModalBox>
      </Modal>
    </>
  );
};

export default FailureModal;
