import styled from "@emotion/styled";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  Typography,
} from "@mui/material";
import HeaderBg from "assets/HeaderBg.png";
import MaskGroup from "assets/MaskGroup.png";
export namespace S {
  export const HeaderContainer = styled(Container)(({ theme }: any) => ({
    backgroundImage: `url(${MaskGroup})`,
    width: "100%",
    backgroundSize: "cover",
    minHeight: "9rem",
    padding: "1.5rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "0.5rem",
    flex: "wrap",
    [theme.breakpoints.down("sm")]: {
      padding: "0rem",
      background: "#F3F5F8",
    },
  }));
  export const MobileHeaderContainer = styled(Container)(({ theme }: any) => ({
    backgroundImage: `url(${HeaderBg})`,
    padding: "0.8rem 1rem 0.5rem",
    width: "100%",
    backgroundSize: "cover",
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
  }));

  export const HeaderContainerText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "31px",
    color: "#0B213E",
    textAlign: "center",
  }));

  export const HeaderContainerBox = styled(Box)(({ theme }: any) => ({
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "5px",
    gap: "0.3rem",
    [theme.breakpoints.down("sm")]: {
      img: {
        width: "110px",
        height: "38px",
      },
      marginTop: "0",
    },
  }));
  export const MobileHeaderCard = styled(Card)(({ theme }: any) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "0.3rem",
    // padding: "1rem",
    margin: "1.5rem",
    marginTop: "0.8rem",
    shadowColor: "#000000",
    boxShadow: "none",
    [theme.breakpoints.down("sm")]: {
      margin: "1rem",
      marginBottom: "0.3rem",
    },
  }));
  export const MobileHeaderCardTitle = styled(Typography)(({ theme }: any) => ({
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "31px",
    textAlign: "center",
    color: "#131A22",
    [theme.breakpoints.down("sm")]: {
      fontSize: "22px",
    },
  }));
  export const MobileHeaderCardSubTitle = styled(Typography)(
    ({ theme }: any) => ({
      fontWeight: 400,
      fontSize: "13px",
      lineHeight: "17px",
      textAlign: "center",
      color: "#5D5D5E",
    })
  );
  export const HeaderContainerSubText = styled(Typography)(
    ({ theme }: any) => ({
      fontWeight: 500,
      fontSize: "15px",
      lineHeight: "20px",
      color: "#0B213E",
      textAlign: "center",
    })
  );

  export const CustomContainer = styled(Container)(({ theme }: any) => ({
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "column",
    padding: "1.5rem 0 2rem 0",
    flexWrap: "wrap",
    gap: "0.5rem",
    width: "100%",
    minHeight: "calc(100vh - 21rem)",
    background: "#F3F5F8",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem 0 ",
    },
  }));

  export const MenuContainer = styled(Container)(({ theme }: any) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "1rem",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      gap: "0.6rem",
    },
  }));

  export const MenuContainerText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "28px",
    textAlign: "right",
    letterSpacing: "-0.02em",
    // color: "#646464",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
      lineHeight: "20px",
    },
  }));

  export const MenuContainerCheckBox = styled(Checkbox)(({ theme }: any) => ({
    // color: "#646464",
    color: "transparent",
    borderRadius: "50%",
    boxShadow: "none",
    height: "21px",
    width: "21px",
    marginTop: "-40px",
    marginRight: "-6px",
    "&.Mui-checked": {
      color: "#45B549",
      backgroundColor: "white",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "-30px",
      height: "10px",
      width: "10px",
    },
  }));

export const MenuContainerBox = styled(Box)(({ theme }: any) => ({
  // border: "1px solid #646464",
  borderRadius: "8px",
  height: "44px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "0 0 0 0.5rem",
  gap: "0.5rem",
  cursor: "pointer",
  [theme.breakpoints.down("sm")]: {
    height: "34px",
    gap: "0rem",
    padding: "0 0 0 0.3rem",
  },
}));

  export const CardContainer = styled(Container)(({ theme }: any) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "2rem",
    width: "100%",
    padding: "1rem",
  }));
  export const PageBtn = styled(Button)(({ theme }: any) => ({
    background: "#66717B",
    borderRadius: "8px",
    minWidth: "44px !important",
    height: "44px",
    color: theme.palette.primary.contrastText,
    boxShadow: "none",
    border: "none",
    "&:hover": {
      background: `${theme.palette.primary.main} !important`,
      boxShadow: "none",
      color: theme.palette.primary.contrastText,
    },
  }));

  export const PageSideBtn = styled(PageBtn)(({ theme }: any) => ({
    background: "#afb8c3",
    borderRadius: "8px",
    color: "black",
  }));
}
