import React, { FC, Fragment, useEffect, useState } from "react";
import PrimaryButton from "../buttons/PrimaryButton";
import { S } from "./styled";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import SuccessModal from "../Modal/SuccessModal";
import FailureModal from "../Modal/FailureModal";
import WifiImg from "assets/icons/wifi.svg";
import CalendarImg from "assets/icons/calendar.svg";
import TagsImg from "assets/icons/tags.svg";
import Img4G from "assets/icons/Img4G.svg";

interface Props {
  data: any;
  handleOpen: () => void;
}

const PrimaryCard: FC<Props> = ({ data, handleOpen }) => {
  const navigate = useNavigate();
  // const [searchParams, setSearchParams] = useSearchParams();
  // const params = useParams();

  // useEffect(() => {
  //   if (!searchParams.get("status")) setSearchParams("");
  // }, [params.id]);

  function gtag_report_conversion(url: any) {
    var callback = function () {
      if (typeof url != "undefined") {
        // window.location = url;
      }
    };
    (window as any).gtag("event", "conversion", {
      send_to: "AW-836950927/vGShCMiP38EDEI-3i48D",
      event_callback: callback,
    });
    return false;
    // (window as any).analytics.track(
    //   "Buy button clicked",
    //   {
    //     event_category: "Buy button clicked",
    //   },
    //   (res: any) => {
    //     console.log(res);
    //   }
    // );
  }

  return (
    <>
      <S.CustomCard>
        <S.CardHeader
          sx={{
            background: `linear-gradient(113.74deg, ${
              data?.countryColor?.start ? data?.countryColor?.start : "#1471DE"
            } 9.6%, ${
              data?.countryColor?.end ? data?.countryColor?.end : "#109D6A"
            } 91.34%)`,
          }}
        >
          <S.CardHeaderBox>
            <S.CardHeaderText>{data.packageName}</S.CardHeaderText>
            <S.CardHeaderSubText>{data.subheader}</S.CardHeaderSubText>
          </S.CardHeaderBox>
          <S.CardHeaderBox sx={{ alignItems: "end", minWidth: "85px" }}>
            <S.CardHeaderText sx={{ textAlign: "end" }}>
              ${data.discountedPrice.toFixed(2)}
            </S.CardHeaderText>
            <S.CardHeaderSubText sx={{ textAlign: "end" }}>
              <span style={{ textDecoration: "line-through" }}>
                ${data.originalPrice}
              </span>{" "}
              ({data.discountpercentage}% off)
            </S.CardHeaderSubText>
          </S.CardHeaderBox>
        </S.CardHeader>

        <S.MainContent>
          <S.MainText>Package Details</S.MainText>
          {data.packageTypes.map((val: any) => {
            return (
              <Fragment key={val.name}>
                {val?.name === "Inflight WiFi" && (
                  <S.MainBox>
                    <S.MainContentBox>
                      <img src={WifiImg} alt="WifiImg" />
                      <S.MainSubText>{val.name}</S.MainSubText>
                    </S.MainContentBox>
                    <S.MainContentBoxText>
                      {val?.dataLimitInBytes / 1048576} MB
                    </S.MainContentBoxText>
                  </S.MainBox>
                )}
                {val?.name === "eSIM" && (
                  <S.MainBox>
                    <S.MainContentBox>
                      <img src={Img4G} alt="Img4G" />
                      <S.MainSubText>
                        {data.country === "All"
                          ? "Global"
                          : data.country === "All EU"
                          ? " EU Regional"
                          : data.country}{" "}
                        eSIM
                      </S.MainSubText>
                    </S.MainContentBox>
                    <S.MainContentBoxText>
                      {val?.dataLimitInBytes / (1024 * 1024) / 1024} GB
                    </S.MainContentBoxText>
                  </S.MainBox>
                )}

                {data.packageTypeId === "6285fef1014c44572e8202b9" &&
                  val?.serviceType === "Inflight WiFi" && (
                    <S.MainBox>
                      <S.MainContentBox>
                        <img src={CalendarImg} alt="CalendarImg" />
                        <S.MainSubText>Active Period</S.MainSubText>
                      </S.MainContentBox>
                      <S.MainContentBoxText>
                        {val?.validityInDays >= 999
                          ? "Forever"
                          : val?.validityInDays + " Days"}
                      </S.MainContentBoxText>
                    </S.MainBox>
                  )}
                {(data.packageTypeId === "6285ff10014c44572e8202bb" ||
                  "6285ff0a014c44572e8202ba") &&
                  val?.name === "eSIM" &&
                  val?.name !== "Inflight WiFi" && (
                    <S.MainBox>
                      <S.MainContentBox>
                        <img src={CalendarImg} alt="CalendarImg" />
                        <S.MainSubText>eSIM Active Period</S.MainSubText>
                      </S.MainContentBox>
                      <S.MainContentBoxText>
                        {val?.validityInDays >= 999
                          ? "Forever"
                          : val?.validityInDays + " Days"}
                      </S.MainContentBoxText>
                    </S.MainBox>
                  )}
                {data.packageTypeId === "6285ff10014c44572e8202bb" &&
                  val?.name !== "Inflight WiFi" && (
                    <S.MainBox>
                      <S.MainContentBox>
                        <img src={TagsImg} alt="TagsImg" />
                        <S.MainSubText>GIANT Rewards</S.MainSubText>
                      </S.MainContentBox>
                      <S.MainContentBoxText>
                        {data.packageTypes[0].rewards +
                          data?.packageTypes[1]?.rewards}
                      </S.MainContentBoxText>
                    </S.MainBox>
                  )}
                {data.packageTypeId === "6285ff0a014c44572e8202ba" &&
                  val?.name !== "Inflight WiFi" && (
                    <S.MainBox>
                      <S.MainContentBox>
                        <img src={TagsImg} alt="TagsImg" />
                        <S.MainSubText>GIANT Rewards</S.MainSubText>
                      </S.MainContentBox>
                      <S.MainContentBoxText>{val.rewards}</S.MainContentBoxText>
                    </S.MainBox>
                  )}
                {data.packageTypeId === "6285fef1014c44572e8202b9" &&
                  val?.serviceType === "Inflight WiFi" && (
                    <S.MainBox>
                      <S.MainContentBox>
                        <img src={TagsImg} alt="TagsImg" />
                        <S.MainSubText>GIANT Rewards</S.MainSubText>
                      </S.MainContentBox>
                      <S.MainContentBoxText>{val.rewards}</S.MainContentBoxText>
                    </S.MainBox>
                  )}
              </Fragment>
            );
          })}
        </S.MainContent>

        <S.FooterContent>
          <PrimaryButton
            style={{ height: "50px", width: "55%" }}
            onClick={() => {
              gtag_report_conversion(window.location.href);
              handleOpen();
              navigate(`/packages/${data._id}`);
            }}
          >
            Buy
          </PrimaryButton>
        </S.FooterContent>
      </S.CustomCard>
    </>
  );
};

export default PrimaryCard;
