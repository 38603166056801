import axios from "axios";
import { createContext, useContext, useEffect, useReducer } from "react";

const initialState = {
  isLoading: false,
  isError: false,
  errorMsg: "",
  isSuccess: false,
  successMsg: "",
  cardData: [],
  packageTypes:[],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOADING":
      return { ...state, isLoading: true };
    case "SET_DATA":
      // console.log("payload", action.payload);
      return { ...state, cardData: action.payload,isLoading:false };
    case "SET_PACKAGE_TYPE":
      // console.log("payload", action.payload);
      return { ...state, packageTypes: action.payload };
    default:
      return state;
  }
};

export const AppContext = createContext(initialState);

function AppContextProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getCardDetails = async () => {
    dispatch({ type: "LOADING" });
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/getPackageByType`)
      .then((res) => {
        // console.log("res",res)
        dispatch({type:"SET_DATA",payload: res.data})
      })
      .catch((err) => {});
  };

  const getPackageTypes = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/getPackageTypes`)
      .then((res) => {
        dispatch({
          type: "SET_PACKAGE_TYPE",
          payload: res?.data.reverse(),
        });
      });
  };

  useEffect(() => {
    getCardDetails();
    getPackageTypes();
  }, []);

  let data = {
  ...state,
  dispatch
};
  return (
    <AppContext.Provider value={data}>{props.children}</AppContext.Provider>
  );
}

const useAppContext = () => useContext(AppContext);

export { AppContextProvider, useAppContext };
